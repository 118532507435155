<template>
  <div id="app">
    <div class ="container">
      on hiatus
    </div> 
  </div>
</template>

<script>

export default {
  data() {
    return {
      hover: false
    };
  },
  methods: {
    toggleHover() {
      this.hover = !this.hover;
    },
  },
}

</script>

<style>
#app {
  font-family: 'Fira Mono', Consolas, Menlo, Monaco, 'Courier New', Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.container {
  margin: 0 auto;
  margin-bottom: 100px;
}

.header-msg {
  margin-bottom: 200px;
}

.list{
  margin-bottom: 20px;
}

body {
  padding-top: 50px;
}

.present {
  width: 410px;
  margin: 0 auto;
}
.box, .lid {
  background:
    -webkit-radial-gradient(white 15%, transparent 15.1%),
    -webkit-radial-gradient(white 15%, transparent 15.1%),
  rgb(240, 58, 58);
  background-position: 0 0, 25px 25px;
  background-size: 50px 50px;
  position: relative;
  margin: 0 auto;
}
.box {
  width: 400px;
  height: 250px;
  margin-bottom: 50px;
}
.lid {
  width: 400px;
  height: 70px;
  box-shadow: 1px 2px 3px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 0 2px;
  background-color: rgb(216, 52, 52);
  /* transitions */
  top: 0;
  left: 0;
  transition: 
    top ease-out 0.5s,
    left ease-out 0.5s,
    transform ease-out 0.5s;
}
.box span, .lid span {
  position: absolute;
  display: block;
  background: rgba(235, 199, 0, 0.8);
  box-shadow: 1px 2px 3px rgba(0,0,0,0.1);
}
.box span:first-child {
  width: 100%;
  height: 60px;
  top: 80px;
}
.box span:last-child, .lid span {
  width: 60px;
  height: 100%;
  left: 170px;
}
.lid span {
  left: 172px;
}
.promo {
  font-size: 30px;
  color: black;
  text-align: center;
  position: relative;
  height: 0;
  top: 10px;
  transition: all ease-out 0.7s;
}
.promo p {
  font-size: 24px;
  margin: 0;
}
.promo h2 {
  font-size: 40px;
  margin: 0;
}

/* hover effects */
.present:hover .lid {
  top: -100px;
  transform: rotateZ(10deg);
  left: 10px;
}
.present:hover .promo {
  top: -80px;
}

.lid-clicked {
  top: -100px;
  transform: rotateZ(10deg);
  left: 10px;
}
.promo-clicked {
  top: -80px;
}
</style>
